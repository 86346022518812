<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="4">
                    <h2><strong>List of Subject Classes</strong></h2>
                </b-col>
            </b-row>

            <div class="card">
                <div class="card-body">
                    <b-table
                        responsive
                        :items="items"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :bordered="bordered" 
                        :hover="hover"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        show-empty
                        id="my-table"
                        small
                        @filtered="onFiltered"
                    >   

                        <template #cell(actions)="room">
                            <b-dropdown id="dropdown-right" text="Action" variant="info" size="sm">
                                <b-dropdown-item href="javascript:void(0)" @click="viewSubjectResult(room.item)">View Result</b-dropdown-item>
                                <!-- <b-dropdown-item href="javascript:void(0)" @click="allResults(room.item.id)">View Result</b-dropdown-item> -->
                          </b-dropdown>
                        </template>
                    </b-table>
                </div>

                <div class="card-footer">
                    <b-row class="mb-2">
                        <b-col md="4">
                            <b-form-group
                                label="Showing"
                                label-for="per-page-select"
                                label-cols-md="4"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                class="def"
                                ></b-form-select>
                                 of {{ items.length }} Items
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-pagination
                                aria-controls="my-table"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>

             <b-modal ref="viewSubjectResult" :title="title" hide-footer>
                <b-form @submit.prevent="updateSubject()">
                    <b-form-group class="">
                        <label>Select Session</label>
                        <select v-model="formSubject.session" class="form-control" required>
                            <option value=null> -- Select Session-- </option>
                            <option v-for="option in sessions" :key="option.id" v-bind:value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </b-form-group>
                    
                    <b-form-group class="">
                        <label>Select Term</label>
                        <b-form-select v-model="formSubject.term" :options="termss"></b-form-select>
                    </b-form-group>
                 
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success btn-lg btn-block">Submit</button>
                    </div>
                </b-form>
            </b-modal>
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import { Form } from 'vform';
    
    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
                items: [],
                fields: [
                    { key: 'subject', label: 'Name'},
                    { key: 'class', label: 'Class'},
                    { key: 'actions', label: 'Actions' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 15,
                bordered: true,
                hover: true,
                fixed: true,
                pageOptions: [5, 10, 15, 25, 50, 100, { value: 100, text: "All" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                action: {
                    selected: [],
                    subject: [],
                    student_selected: [],
                    id: '',
                },
                formSubject: new Form({
                    class_id: '',
                    subject_id: '',
                    session: null,
                    term: null,
                }),
                sessions : [],
                terms: [{ text: 'Select Term', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }, { text: 'Annual', value: 4 }],
                termss: [{ text: 'Select Term', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }],
                is_busy: false,
                editmode: false,
            }
        },

        created() {
            this.roleHelper(['1', '2', '5']);
            this.load();
        },

        methods: {
            load(){
                if (this.is_busy) return;
                this.is_busy = true;

                axios.get("/classes/mysubject")
                .then(res => {
                    this.sessions = res.data.sessions;
                    this.items = res.data.subjects;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            viewSubjectResult(subject) {
                console.log(subject)
                this.title = subject.subject + ' Resultsheet';
                this.formSubject.class_id = subject.classes_id;
                this.formSubject.subject_id = subject.subject_id;
                this.$refs['viewSubjectResult'].show();
            },
            
            getResult(){
                if(!this.allres.session || !this.allres.term){
                    Swal.fire(
                        "Failed!",
                        "Please select all the requirements.",
                        "warning"
                    );
                }
                else
                {
                    this.$refs['allResult'].hide();
                    this.$router.push({path: '/result/viewall/'+ this.allres.id + '/' + this.allres.session + '/' + this.allres.term });
                } 
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },

            updateSubject(){
                this.$refs['viewSubjectResult'].hide();
               
                axios.post('/result', this.formSubject)
                .then(()=>{
                    this.$router.push({path: '/result/add/'+ this.formSubject.class_id + '/' + this.formSubject.session + '/' + this.formSubject.subject_id + '/' + this.formSubject.term });
                }) 
                .catch();
            },

            updateUserResult(){
                
                if(!this.formResult.session || !this.formResult.term){
                    Swal.fire(
                        "Failed!",
                        "Please select all the requirements.",
                        "warning"
                    );
                }
                else
                {
                    this.$refs['viewResult'].hide();
                    if(this.formResult.term==4) {
                        this.$router.push({path: '/result/view/annual/'+ this.formResult.student + '/' + this.formResult.session + '/' + this.formResult.term });
                    }
                    else {
                        this.$router.push({path: '/result/view/'+ this.formResult.student + '/' + this.formResult.session + '/' + this.formResult.term });
                    }
                    
                } 
            },
        },

        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                .filter(f => f.sortable)
                .map(f => {
                return { text: f.label, value: f.key }
                })
            },

            selectAll: {
                get: function () {
                    return this.items ? this.action.selected.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            },

            selectAllSubject: {
                get: function () {
                    return this.items ? this.action.subject.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            }
        },
    }
</script>
<style>
    .cont {
        background: #F3F2F7;
    }

    .def {
        width: 70px;
    }
</style>